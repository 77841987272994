<template>
  <div>
    <div class="title flexB">
      <h1>{{ $t("top-select-total-view-dashboard") }}</h1>
      <div>
<!--        <span
        >{{ moment().format("YYYY.MM.DD(dd) HH:mm") }} {{ $t("data-today") }}</span
        >-->
        <button class="medium" @click="handleRefresh">{{ $t("btn-refresh") }}</button>
<!--        <button class="small margin6"  style="background: white;" @click="handlePop">
          {{ $t("btn-new-browser") }}
        </button>-->
      </div>
    </div>
    <div class="contents overall"> <!--style="min-height: calc(100vh - 590px);" >-->
      <div class="flexB boxCol2-3" :style="{ 'height': typeChangeUpShowHeight +'px'}" >
        <div class="box cntWrap">
          <h2 class="bold">{{ $t("overall-count-title") }}</h2>
          <div class="summary flex" style="flex-wrap: wrap; flex-direction: column; padding: 16px; height: calc( 100% - 32px); justify-content: center;">
              <div class="flexB" style="
                   height: calc( 33.33% - 10px);
                   justify-items: center;
                   margin-bottom: 5px;
                   padding: 13px 16px;
                   border-radius: 4px;
                   border: 1px solid #ebebeb;
                   background: #f4f4f4;">
                <div class="boxin">
                  <img src="@/assets/images/icon_svg/ic_ds_all_site.svg" alt="icon" />
                  <b> {{ $t("overall-data-event") }}</b>
                </div>
                <p>
              <span>{{ eventTotal || 0 }}</span
              ><b> {{ $t("overall-data-event-ea") }}</b>
                </p>
              </div>
            <!--참여 디바이스 개수(디자인 변경)-->
<!--            <div class="flexB" style="
                   height: calc( 33.33% - 10px);
                   justify-items: center;
                   margin-top: 5px;
                   margin-bottom: 5px;
                   padding: 13px 16px;
                   border-radius: 4px;
                   border: 1px solid #ebebeb;
                   background: #f4f4f4;">
              <div class="boxin">
                <img src="@/assets/images/icon_svg/ic_ds_all_drone.svg" alt="icon" />
                <b> {{ $t("overall-data-device") }}</b>
              </div>
              <p>
              <span>{{ deviceCount || 0 }}</span
              ><b> {{ $t("overall-data-device-ea") }}</b>
              </p>
            </div>-->
            <div class="flex" style=" height: calc( 33.33% - 10px); justify-content: center; margin: 6px 0;">
              <div class="wid50" style="margin-right: 16px; ">
                <div class="flexB">
                  <img src="@/assets/images/icon_svg/ic_ds_all_drone.svg" alt="icon" />
                  <p>
                    <span>{{ deviceCount || 0 }}</span>
                    <b> {{ $t("overall-data-device-ea") }}</b>
                  </p>
                </div>
                <b style="font-size: 1.3rem;"> {{ $t("overall-drone-title") }}</b>
              </div>
              <div class="wid50" style="height: 100%;">
                <div class="flexB">
                  <img src="@/assets/images/icon_svg/ic_ds_all_member.svg" alt="icon" />
                  <p>
                    <span>{{ memberCount || 0 }}</span>
                    <b> {{ $t("overall-data-member-ea") }}</b>
                  </p>
                </div>
                <b style="font-size: 1.3rem" >{{ $t("overall-data-member") }}</b>
              </div>
            </div>
            <div class="flex" style=" height: calc( 33.33% - 10px);justify-content: center;  margin: 6px 0;">
              <div class="wid50" style="margin-right: 16px;">
                <div class="flexB boxin1-1">
                  <img src="@/assets/images/icon_svg/ic_ds_all_live.svg" alt="icon"/>
                  <p>
                    <span>{{ liveTotal || 0 }}</span>
                    <b> {{ $t("overall-data-default-ea") }}</b>
                  </p>
                </div>
                <b style="font-size: 1.3rem"> {{ $t("overall-data-live") }} </b>
              </div>
              <div class="wid50">
                <div class="flexB boxin1-1">
                  <img src="@/assets/images/icon_svg/ic_ds_all_vod.svg" alt="icon" />
                  <p>
                    <span>{{ vodTotal || 0 }}</span>
                    <b> {{ $t("overall-data-default-ea") }}</b>
                  </p>
                </div>
                <b style="font-size: 1.3rem"> {{ $t("overall-data-vod") }}<span style="color: transparent">개 </span></b>
              </div>
            </div>

          </div>
        </div>
        <div class="box mapWrap">
          <h2 class="bold">{{ $t("overall-map-title") }}</h2>
          <div class="map" ref= "map_div">
            <Map ref="map" style="width: 100%; height: 100%;" :loadMapName="loadMap" :mapType="mapType" />
          </div>
        </div>
        <div class="box">
          <h2 class="bold">
            {{ $t("overall-event-table-title") }}
            <router-link :to="`/${accountId}/eventControl/liveListControl`" >
              <span class="more right">
                {{ $t("btn-txt-more") }}
                <img src="@/assets/images/icon_svg/arrow_right.svg" alt="icon"/>
              </span>
            </router-link>
          </h2>
          <div class="tableWrap">
            <table>
              <tr>
                <th>No</th>
                <th>{{ $t("overall-event-table-title-dist") }}</th>
                <th>{{ $t("overall-event-table-title-event-name") }}</th>
                <th>{{ $t("overall-event-table-title-start-date") }}</th>
              </tr>
              <tr v-for="(data, i) in eventList" :key="i">
                <td>{{ eventTotal - i }}</td>
                <td
                    :class="{
                    seoul: data.address.includes('서울'),
                    busan: data.address.includes('부산'),
                    daegu: data.address.includes('대구'),
                    incheon: data.address.includes('인천'),
                    gwangju: data.address.includes('광주'),
                    daejeon: data.address.includes('대전'),
                    ulsan: data.address.includes('울산'),
                    sejong: data.address.includes('세종'),
                    gyeonggi: data.address.includes('경기'),
                    gangwon: data.address.includes('강원'),
                    chungbuk: data.address.includes('충청북도'),
                    chungnam: data.address.includes('충청남도'),
                    jeonbuk: data.address.includes('전라북도'),
                    jeonnam: data.address.includes('전라남도'),
                    gyeongbuk: data.address.includes('경상북도'),
                    gyeonggnam: data.address.includes('경상남도'),
                    jeju: data.address.includes('제주'),
                  }"
                >
                  {{ data.address.split(" ")[0] }}
                </td>
                <td class="link" @click="handleEvent(data)">
                  {{ data.title }}
                </td>
  <!--              <td>{{ data.memberCount }}</td>
                <td>{{ data.liveCount }}</td>
                <td>{{ data.contentsCount }}</td>-->
                <td>
                  {{
                    data.openDate
                        ? moment(data.openDate).format("YYYY-MM-DD")
                        : "-"
                  }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="flexB boxCol2-3" :style="{ 'height': typeChangeDownShowHeight + 'px' }">
        <div class="box">
          <h2 class="bold">
            {{ $t("overall-drone-title") }}
            <router-link to="dashDeviceList">
              <span class="more right">
                {{ $t("btn-txt-more") }}
                <img src="@/assets/images/icon_svg/arrow_right.svg" alt="icon"/>
              </span>
            </router-link>
          </h2>
          <div class="droneWrap" style="overflow: auto;">
            <div class="drone flex" v-for="(data, i) in visibleList" :key="i"  style="height: 66px;">
              <!--              <div v-if="deviceList[index - 1] == undefined || deviceList[index - 1].isLoad == false">
                              <div class="flexB" style="justify-content: center;">
                                <div>
                                  <p class="bold" >{{ $t("no-live-channel") }}</p>
                                </div>
                              </div>
                            </div>-->

              <div class="thumBox">
                <img
                    :src="getDeviceImg(data)"
                    alt="썸네일"
                    class="deviceImg"
                />
              </div>
              <div class="droneBox" style="min-width: 200px; border-bottom: 1px solid #ebebeb;">
                <p class="bold">{{data.eventTitle}}</p>
                <div class="flexB">
                  <p class="drData wd3">{{data.deviceName}}</p>
                  <p class="drData wd3">{{data.deviceModelName}}</p>
                  <p class="drData wd2">{{data.workerDepartmentName}}</p>
                  <p class="drData wd2">{{data.workerName == null? "-" : data.workerName}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--        <div class="box liveBox">
                  <h2 class="bold">
                    {{ $t("overall-live-title") }}
        &lt;!&ndash;            <router-link to="dashChannelList">
                      <span class="more right">
                        {{ $t("btn-txt-more") }}
                        <img
                            src="@/assets/images/icon_svg/arrow_right.svg"
                            alt="icon"
                        />
                      </span>
                    </router-link>&ndash;&gt;
                  </h2>
                  <div class="liveWrap flex">
                    <template  v-for="(index) in 4 " >
                      <div class="live" :key="'live'+index">

                        <div v-if="viewChannelList[index - 1] == undefined || viewChannelList[index - 1].isLoad == false">
                          <no-video/>
                          <div class="flexB" style="justify-content: center;">
                            <div class="channel">
                              <p class="bold">{{ $t("no-live-channel") }}</p>
                            </div>
                          </div>
                        </div>

                        <div class="live-item" v-if="viewChannelList[index - 1] != undefined" >
                          <div v-show="viewChannelList[index - 1].isLoad == true">
                            <Video class="live-video" :src="getLiveUrl(viewChannelList[index - 1])" :isVod="false" :isAuto = "true" :isControls="false" :playerId="viewChannelList[index - 1].channelId" @isLoadedmetadata="playerLoadData" />
                            <span class="small">{{ viewChannelList[index - 1].name }}</span>
                            <div class="flexB">
                              <div class="channel">
                                <p class="bold">{{ viewChannelList[index - 1].eventTitle }} / {{ viewChannelList[index - 1].assignedDepartmentName}}</p>
                                <p>{{ viewChannelList[index - 1].assignedUserName }}</p>
                                <p>{{moment(viewChannelList[index - 1].startDate).format("YYYY.MM.DD HH:mm") }}</p>
                              </div>
                              <button @click="playerOpen('live', viewChannelList[index - 1].channelId)">
                                <img
                                    src="@/assets/images/icon_svg/ic_pop_up.svg"
                                    alt="icon"
                                />
                              </button>
                            </div>
                          </div>
                        </div>

                      </div>
                    </template>
                  </div>
                </div>-->
        <div class="box vodBox">
          <h2 class="bold">
            {{ $t("overall-vod-title") }}
            <router-link :to="`/${accountId}/dashboard/dashContentsList`">
              <span class="more right">
                {{ $t("btn-txt-more") }}
                <img
                    src="@/assets/images/icon_svg/arrow_right.svg"
                    alt="icon"
                />
              </span>
            </router-link>
          </h2>
          <div class="videoWrap flex">
            <div class="video" v-for="(data, i) in vodList" :key="'video_'+i">
              <div class="thumBox" @click="playerOpen('vodPlay', data.contentsId)" style="position: relative;">
                <img :src="baseUrl + data.thumbnailUri" style="object-fit: cover; width: 100%;height: 100%;"/>
              </div>
              <p class="bold">{{ data.title }}</p>
              <p>{{ data.eventTitle }}</p>
              <p>{{moment(data.registerDate).format("YYYY.MM.DD HH:mm") }}</p>
            </div>
            <div class="video" v-for="(n, j) in 4 - (vodList.length != undefined ? vodList.length : 0)"  :key="'novideo' + j">
              <div class="novideo"><span>{{ $t("ready-vod") }}</span></div>
            </div>
          </div>
        </div>
        <div class="box noticeList flexB">
          <div class="infoWrap" style="width:100%; height: 100%;">
            <div class="flexB">
              <h2 class="bold">
                {{ $t("user-home-user-system-notice") }}
              </h2>
<!--              <button class="small redBtn" style="height: 2.5rem; line-height: 2.5rem;" @click="handleTab"> 공지작성 </button>-->
            </div>
            <div class="tableBox" style="height: calc(100% - 30px); overflow-y: auto;">
              <table class="noticeTable" >
                <colgroup>
                  <col style="width: 10%;"/>
                  <col style="width: 70%"/>
                  <col style="width: 20%"/>
                </colgroup>
                <tr>
                  <th>No</th>
                  <th>{{ $t("user-home-user-system-notice-contents") }}</th>
                  <th>{{ $t("user-home-user-system-notice-date") }}</th>
                </tr>
<!--                <tr
                    v-for="(data, i) in 11"
                    :key="i"
                    @click="handleDetail"
                >
                  <td>{{ 11 - (size * currentPage + i) }}</td>
                  <td>2022.08.15 v1.0 버전 업데이트 안내입니다.</td>
                  <td>2021.02.02.19:30:00</td>
                </tr>-->
                <tr v-for="(data, i) in noticeAppList" :key="i" @click="handleDetail(data.noticeAppId)">
                  <td>{{ noticeAppTotal - (noticeAppCurrentPage * noticeAppPageSize + i) }}</td>
                  <td>{{data.title}}</td>
                  <td>{{ data ? moment(data.registerDate).format("YYYY-MM-DD HH:mm:SS") : "" }}</td>
                </tr>
                <tr v-if="noticeAppList.length == 0">
                  <td colspan="3" style="width: 100%; height:42px; background: white;">
                    <span>{{ $t("data-none-result") }}</span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
    <transition name="fade">
      <div v-if="visible == 1" class="dim on">
        <SystemNotice @close="close" :noticeAppId="this.noticeAppId" />
      </div>
      <div v-if="visible == 2" class="dim on">
        <SystemNoticeDetail @close="close" @patch="patch" :noticeAppId="this.noticeAppId"/>
      </div>
    </transition>

  </div>
</template>
<style scoped>
.live-video {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
}
.drData{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
.wd3{
  width: 30%;
}
.wd2{
  width: 20%;
}
.mainMenu_admin{display: block;}
</style>
<script>
import moment from "moment";
import { baseUrl } from "@/config/env";
import { mapState } from "vuex";
import Map from "@/components/map/Map";
import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapCityType from "@/components/map/enum/MapCityType";

import {playerPopOpen } from "@/util/popup";

import {fetchEventList, CountEventList, fetchEventJoinList,} from "@/api/dashboard";
import { fetchVodList } from "@/api/contents";
import { fetchNoticeAppList } from "@/api/event";
import { fetchPointList } from "@/api/point";
import { fetchStatisticsWorkerList, fetchStatisticsEventList, fetchStatisticsDeviceList, fetchAddrList,} from "@/api/statistics";
import { getDeviceImgUrl, getDeviceImgDefault, fetchDeviceLogList } from "@/api/device";
import { format } from "@/mixins/format";
import MapType from "@/components/map/enum/MapType";

import SystemNotice from "@/components/pop/SystemNotice";
import SystemNoticeDetail from "../../components/pop/SystemNoticeDetail";

export default {
  components: {
    SystemNoticeDetail,
    //LineOverallEventChart,
    /*LineOverallSupportChart,
    LineOverallDeiceChart,*/
    Map,
    SystemNotice,
    //Video,
    //noVideo,
  },
  mixins: [format],
  name: "Main",
  data() {
    return {
      index: 0,
      accountId: localStorage.getItem("accountId"),
      chId: null,
      baseUrl: baseUrl,
      moment: moment,
      loadMap : 'kakao',
      //화면 사이즈 조절 region
      windowHeight: window.innerHeight,
      typeChangeUpShowHeight: 0,
      typeChangeDownShowHeight: 0,
      typeMaxUpShowHeight : 360,
      //화면 사이즈 조절 endregion
      eventCount: 0,
      deviceCount: 0,
      deviceList : [],

      mapType : MapType.ID_SATELLITE,
      //시스템 공지
      visible: false,
      memo: "",
      noticeAppList:[],
      noticeAppTotal: 0,
      noticeAppCurrentPage: 0,
      noticeAppPageSize: 20,
      noticeAppId: "",

      visibleList:[],
      liveCount: 0,
      memberCount: 0,
      trafficValue: 0,
      eventList: [],
      eventTotal: 0,
      workerList: [],
      controller: [],
      vodList: [],
      vodTotal: 0,
      //라이브
      //viewChannelList : [],
      //liveList: [],
      liveTotal: 0,
      pointTotal: 0,
      support: [],
      event: [],
      traffic: [],
      //liveSize : 4,
      chartEvent : [],
      chartDevice : [],
      chartSupport : [],
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 1, sub: 0 });
    window.addEventListener('resize', this.handleResize);
    this.typeChangeUpShowHeight = (this.windowHeight - 110) *0.40;
    this.typeChangeDownShowHeight = (this.windowHeight - 110) * 0.57;

  },
  async mounted() {
    await this.getDeviceLogList();
    this.getCount();
    this.getEventList();
    this.getVodList();
    this.getPointList();
    this.getNoticeAppList();
    //await this.getChannelList();
    this.getWorkerStatisticsList();
    this.getEventStatisticsList();
    this.getDeviceStatisticsList();
    /*this.interval = setInterval(() => {
      this.getChannelList();
    }, 2000);*/
    this.initMapData(this.currentLang );

    // 1. ResizeObserver 객체 만들기
    if(this.$refs.map != undefined && this.$refs.map_div != undefined) {
      const observer = new ResizeObserver(entries => {
        for (let entry of entries) {
          const {width, height} = entry.contentRect;
          width;
          height;
          this.$refs.map.setLayout();
        }
      });

      // 2. 감지할 요소 추가하기
      observer.observe(this.$refs.map_div);
    }


  },
  mount(){
  },
  watch : {
    currentLang(value){
      this.initMapData(value);
    },
    typeChangeUpShowHeight(value){
      var upHeight = value;
      if(upHeight != this.typeMaxUpShowHeight) {
        upHeight = this.typeMaxUpShowHeight;
      }
      this.typeChangeUpShowHeight = upHeight;
    },
  },
  computed: {
    ...mapState({
      gnbVisible: state => state.store.gnbVisible,
      currentLang: state => state.store.currentLang,
    }),
  },
  methods: {
    handleTab() {
      this.visible = 1;
      this.noticeAppId = null;
    },
    handleDetail(noticeAppId) {
      this.noticeAppId = noticeAppId;
      this.visible = 2;
    },
    patch(id){
      this.visible = 1;
      this.noticeAppId = id;
    },
    close() {
      this.visible = false;
    },
    initMapData(value){
      if(value == 'en') {
        this.loadMap = 'google'
      } else {
        this.loadMap = 'kakao'
      }
      this.getAddrList();
    },
    getDeviceImg(data){
      return data.deviceDefaultIsImage ? getDeviceImgDefault(data.deviceManufacturerId, data.deviceModelId) : getDeviceImgUrl(this.accountId, data.deviceId) + '?date='+(new Date)
    },
    playerLoadData(id, isLoad){
      var isFindIndex = (data) => {
        return data.channelId == id;
      };
      var findIndex = this.viewChannelList.findIndex(isFindIndex)
      if(findIndex != -1) {
        this.viewChannelList[findIndex].isLoad = isLoad;
        this.$set(this.viewChannelList, findIndex, this.viewChannelList[findIndex]);
      }
    },
    //페이지 리사이즈
    handleResize(){
      this.windowHeight = window.innerHeight;
      this.typeChangeUpShowHeight = (this.windowHeight - 110) *0.40;
      this.typeChangeDownShowHeight = (this.windowHeight - 110) * 0.57;
    },
    handleEvent(data) {
      //this.$router.push({ name: "liveInfoControl", query: { eventId: eventId } });
      if(!this.$store.state.store.isAdmin && data.managerDepartmentId != this.$store.state.store.departmentId){
        if(data.isAuth == true && data.authCount == 0) {
          alert(this.$t("user-not-permission"));
          return;
        }
      }
      document.location.href = `/${this.accountId}/eventControl/${data.eventId}/SkyView`;

      //Site Control 새창
      /*this.routeUrl = this.$router.resolve({ path: `/${this.accountId}/eventControl/${data.eventId}/SkyView` });
      newTab(this.routeUrl.href, `SkyView_${data.eventId}`);*/
    },
    /*getDeviceTypeName(type){
      return getDeviceTypeName(type);
    },*/
    /*getLiveUrl(channelData){
      var url = getLiveUrl(channelData);
      if(url == '-') {
        return ''
      }
      return url
    },*/
    /*드론 운영 목록*/
    async getDeviceLogList(){
      let params = {
        /*eventId : this.eventId,*/
        pageSize : 5,
        pageNumber : this.currentPage,
        keyword : this.keyword,
        keywordType : this.keywordType,
        isStartTime : true,
        isEndTime : true,
      };
      if(this.startYmd != null && this.endYmd != null) {
        params["startDateRange.from"] = moment(this.startYmd).format("YYYY-MM-DD");
        params["startDateRange.to"] = moment(this.endYmd).format("YYYY-MM-DD");
      }
      fetchDeviceLogList(params).then((res) => {
        if (res.data.result == 0) {
          this.visibleList = res.data.data.content
          this.visibleTotal = res.data.data.total
        }
      });

    },
    getCount() {
      let workerParams = {
        pageSize : 1,
        eventStatuses : "Open",
        workerTypes : 'Worker,Controller'
      }
      fetchEventJoinList('worker',workerParams).then((res) => {
        if(res.data.result == 0) {
          this.memberCount = res.data.data.total
        }
      });
      let deviceParams = {
        pageSize : 5,
        pageNumber : 0,
        keywordType: 'eventControl',
        isStartTime : true,
        isEndTime : true,
        eventStatuses : "Open"
      }
      fetchDeviceLogList(deviceParams).then((res) => {
        if(res.data.result == 0) {
          this.deviceCount = res.data.data.total;
          this.deviceList = res.data.data.content;
        }
      });
    },
    getWorkerStatisticsList() {
      let params = {
        dateRangeValue: 7,
      };

      var chartDate= []
      fetchStatisticsWorkerList(params).then((res) => {
        if(res.data.data.reference != null) {
          res.data.data.reference.forEach((item) => {
            chartDate.push(this.customFormatter(item.date));
            this.chartSupport.push(item.totalData);
          });
        }

      });
    },
    getEventStatisticsList() {
      let params = {
        dateRangeValue: 7,
      };

      fetchStatisticsEventList(params).then((res) => {
        if(res.data.data.reference != null) {
          res.data.data.reference.forEach((item) => {
            this.chartEvent.push(item.totalData);
          });
        }
      });
    },
    getDeviceStatisticsList() {
      let params = {
        dateRangeValue: 7,
      };

      fetchStatisticsDeviceList(params).then((res) => {
        if(res.data.data.reference != null) {
          res.data.data.reference.forEach((item) => {
            this.chartDevice.push(item.device);
          });
        }
      });
    },


    customFormatter(date) {
      return moment(date).format("MM-DD");
    },

    getEventList() {
      let params = {
        pageNumber: 0,
        statuses: "Open",
      };

      fetchEventList(params).then((res) => {
        this.eventList = res.data.data.content;
        this.eventTotal = res.data.data.total;
      });
    },
    getZoomLevel(){
      return this.currentLang == "en" ? 6 : 14
    },
    getAddrList() {
      this.$refs.map.onLngLatToMoveCenter(128.583052, 35.298838)
      this.$refs.map.onSetZoomLevel(this.getZoomLevel());
      let params = {
        dateRangeValue: 1,
      };
      fetchAddrList(params).then((res) => {
        res.data.data.reference.forEach((item) => {
          this.event.push(item.total);
        });
      });
      CountEventList().then((res) => {
        if (res.data.result == 0) {
          let keys = Object.keys(res.data.data);
          let cityList = [];
          keys.forEach(key => {
            if(key != "total" && key!= "date"){
              let mapMarkerData = new MapMarkerData();
              mapMarkerData.id = key;
              mapMarkerData.name = res.data.data[key];
              cityList.push(mapMarkerData);
            }
          });
          this.$refs.map.onCityMarkerCreate(MapCityType.ID_KOREA, cityList);
        }
      });
    },
    getVodList() {
      let params = {
        pageNumber: 0,
        pageSize: 4,
        statuses: "Close,Open",
        eventStatus : "Open",
        deviceType : "Drone,Mobile"
      };
      fetchVodList(params).then((res) => {
        this.vodTotal = res.data.data.total;
        this.vodList = res.data.data.content;
      });
    },
    getPointList(){
      let params = {
        pageSize: 1,
        eventStatus : "Open"
      };
      fetchPointList(params).then((res) => {
        this.pointTotal = res.data.data.total;
      });

    },
    /*async getChannelList() {
      let params = {
        pageSize: this.liveSize,
        assignedStatus : "Assigned",
      };

      fetchChannelList(params).then((res) => {
        this.liveTotal = res.data.data.total;
        this.liveList = res.data.data.content;


        //채널 추가 및 삭제 로직
        //삭제 아이디 찾기 위한 데이터 넣기
        let delIds = [];
        this.viewChannelList.forEach(item => {
          delIds.push(item.channelId.toString());
        });

        //채널 기존 채널 있는지 확인 및 삭제 아이디 에서 삭제
        this.liveList.forEach(live => {
          let findIndex = delIds.findIndex(item => item == live.channelId);
          if(findIndex != -1) {
            delIds.splice(findIndex,1);
          }
        });

        //삭제 아이디 있는 경우 채널에서 삭제
        delIds.forEach(delId => {
          let findIndex = this.viewChannelList.findIndex(item => item.channelId == delId);
          if(findIndex != -1){
            this.viewChannelList.splice(findIndex,1);
          }
        })

        //채널 추가
        for(var i = 0; i < this.liveList.length ; i++) {
          let live = this.liveList[i];
          let findIndex = this.viewChannelList.findIndex(item => item.channelId == live.channelId);
          if(findIndex == -1){
            live.isLoad = false;
            this.viewChannelList.push(live)
          }
        }
      });
    },*/
    handlePop() {
      this.index = this.index + 1;
      window.open(
          `/${this.accountId}/dashboard/overall?new=true`,
          `overallNewWindow${this.index}`,
          "left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no"
      );
    },
    handleRefresh() {
      this.$router.go();
    },

    async playerOpen(type, id) {
      playerPopOpen(type,id);
    },
    getNoticeAppList(){
      let params = {
          pageNumber: this.noticeAppCurrentPage,
          pageSize: this.noticeAppPageSize,
          searchDate: moment(new Date()).format("YYYY-MM-DD"),
          status: "Used",
      };
      fetchNoticeAppList(params).then((res)=>{
          this.noticeAppList = res.data.data.content;
          this.noticeAppTotal = res.data.data.total;
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
